<template>
  <v-text-field
    v-if="visible"
    v-model.trim="value"
    :label="label"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    :type="show ? 'text' : 'password'"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    @blur="handleBlur"
    @click:append="show = !show"
  >
    <template #append-inner>
      <mtf-field-icon
        :meta="meta"
        :has-errors="hasErrors"
      />
    </template>
  </v-text-field>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useMatterificField } from '#imports';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldPassword',
  components: { MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  setup(props, context) {
    const { meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    );
    const show = ref(false);
    return {
      meta,
      value,
      errors,
      handleBlur,
      validate,
      show,
      hasErrors
    };
  }
});
</script>
